<template>
  <BDropdown class="btn btn-account" right block menu-class="shadow-sm" :text="name">
    <slot name="actions" :actions="actions">
      <template v-for="action in actions">
        <slot :name="'action-' + action.key" :action="action">
          <BDropdownItem v-if="action.link" :key="action.key" :to="action.link" :active="$route.name === action.link.name">
            <slot :name="'action-link-label-' + action.key" :action="action" :label="action.label" :link="action.link">
              {{ action.label }}
            </slot>
          </BDropdownItem>
          <BDropdownItem v-else-if="action.event" :key="action.key" :active="activeAction === action.event" @click.prevent="actionSelected(action.event)">
            <slot :name="'action-content-' + action.key" :action="action" :label="action.label" :event="action.event">
              {{ action.label }}
            </slot>
          </BDropdownItem>
          <BDropdownItem v-else :key="action.key">
            <slot :name="'action-content-' + action.key" :action="action">
              {{ action.label }}
            </slot>
          </BDropdownItem>
        </slot>
      </template>
    </slot>
  </BDropdown>
</template>

<script>

import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: 'account-dropdown',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: () => [
        { key: 'profile', link: { name: 'profile' }, label: 'Profilo' },
        { key: 'change-password', link: { name: 'change-password' }, label: 'Cambia password' },
        { key: 'logout', link: { name: 'logout' }, label: 'Logout' },
      ],
    },
    activeAction: {
      type: String,
      default: null,
    },
  },
  computed: {
    name () {
      return this.label || this.$oauth2?.me?.name || 'Utente';
    },
  },
  methods: {
    actionSelected (actionName) {
      this.$emit('action-selected', actionName);
    },
  },
};

</script>

<style lang="scss">

$account-dropdown-toggle-bg-color: transparent !default;
$account-dropdown-bg-color: white;

.btn.btn-account.dropdown {
  padding: 0 !important;
  border: none!important;

  .dropdown-toggle {
    background: $account-dropdown-toggle-bg-color;
    border: none;
    padding: 4px 15px;
    font-weight: bold;
    display: flex;
    align-items: center;

    &::after {
      margin-left: 15px;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f0d7";
      border: none;
    }

    &:focus {
      box-shadow: none!important;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    border: none;
    border-radius: 0 0 $border-radius $border-radius;
    background: $account-dropdown-bg-color;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .dropdown-item {
      text-align: right;
      padding: 8px 16px;

      &.active,
      &:active {
        background: rgba($body-color, .05);
        color: inherit;
        font-weight: bold;
      }

      &.hover,
      &:hover {
        background: rgba($body-color, .05);
      }
    }
    &:focus {
      border: none;
      outline: none;
    }
  }

  &.show {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .dropdown-toggle {

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &:hover {
    background: $primary;
  }
}

</style>
